.Footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 20px;
  background-color: gray;
  border-top: 5px solid white;

  width: 100%;
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;

    h2 {
      font-size: 24px;
      margin-bottom: 10px;
      color: white;
    }

    .form-group {
      width: 100%;

      label {
        font-weight: bold;
        display: block;
        margin-bottom: 5px;
        color: white;
      }

      input[type="text"],
      input[type="email"],
      input[type="phone"],
      input[type="company"],
      textarea {
        resize: none;
        height: 1.5em;
        border: none;
        background-color: transparent;
        color: white;
        padding: 0;
        margin-bottom: 10px;
        border-bottom: 2px solid white;
        width: 80%;
      }

      textarea {
        resize: vertical;
        min-height: 100px;
      }
    }

    .submit-button {
      background-color: red;
      color: #fff;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: green;
      }
    }
  }
  &-contact {
    display: flex;
    flex-direction: column;
    &_card {
      display: flex;
      flex-direction: column;
      align-items: center;
      display: inline-block;
      &--txt {
        font-size: 12px;
        color: white;
      }
      &--map {
        height: 15vh;
        width: 40vw;
        box-shadow: 10px 5px 5px black;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .Footer {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    height: 91vh;
    .contact-form {
      width: 60%;
      padding-right: 7vw;
      border-right: 2px solid grey;
      h2 {
        padding-bottom: 5vh;
      }

      .form-group {
        padding-bottom: 3vh;
        label {
        }

        input[type="text"],
        input[type="email"],
        input[type="phone"],
        input[type="company"],
        textarea {
        }

        textarea {
        }
      }

      .submit-button {
        &:hover {
        }
      }
    }
    &-contact {
      flex-direction: column;
      width: 30%;
      padding-left: 2vw;
      &_card {
        border-top: none;
        &--txt {
          font-size: 12px;
        }
        &--map {
          height: 15vh;
          width: 15vw;
          box-shadow: 10px 5px 5px black;
        }
      }
    }
  }
}
