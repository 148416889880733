.LinksOfInterest {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #00539f;
  padding-bottom: 4vh;

  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &_title {
      font-size: 5vw;
      font-weight: 600;
      color: white;
      padding-top: 3vh;
      padding-bottom: 1vh;
    }

    &_card {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &--links {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-bottom: 1px solid white;

        &__link {
          font-size: 4vw;
          margin-bottom: -2vh;
          font-weight: 600;
        }

        &__txt {
          font-size: 3vw;
          padding-top: 1vh;
          font-weight: 600;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
    .LinksOfInterest {
        height: 90vh;
        &-container {
      
          &_title {
            font-size: 3vw;
          }
      
          &_card {
            flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-evenly;
      
            &--links {
                width: 45%;
      
              &__link {
                font-size: 1.2vw;
              }
      
              &__txt {
                font-size: 1vw;
              }
            }
          }
        }
      }
}
