@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;700;800&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  background-color: white;
}
