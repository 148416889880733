h2 {
  vertical-align: center;
  text-align: center;
}

html,
body {
  margin: 0;
  height: 100%;
}

* {
  box-sizing: border-box;
}

.top-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: #00539f;
  color: #fff;
  height: 90px;
  padding: 1em;
}

.menu {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu > li {
  display: flex;
  align-items: flex-end;
  margin: 0 1rem;
  overflow: hidden;
  cursor: pointer;
  font-weight: 600;
}

a {
  text-decoration: none;
  color: white;
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #fff;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: "";
  margin-top: -8px;
}

.menu-button::after {
  content: "";
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

.containerimg {
  width: 15vw;
  &-img {
    padding-top: 2vh;
    width: 100%;
  }
}

.traduc {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  &-txt {
    font-weight: 600;
    font-size: 1.1vw;
  }
  &-img {
    padding-right: 0.5vw;
    width: 1.8vw;
    padding-bottom: 0.5vh;
  }
}

@media (max-width: 700px) {
  .top-nav {
    height: 10vh;
  }

  .menu-button-container {
    display: flex;
  }
  .menu {
    position: absolute;
    margin-top: 50px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  #menu-toggle ~ .menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~ .menu li {
    border: 1px solid #333;
    height: 2.5em;
    padding: 0.5em;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .menu > li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;
    background-color: #00539f;
  }
  .menu > li:not(:last-child) {
    border-bottom: 1px solid #444;
  }

  .containerimg {
    width: 50vw;
    &-img {
      padding-top: 0.5vh;
      width: 100%;
    }
  }

  .traduc {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    &-txt {
      font-weight: 600;
      font-size: 3.5vw;
    }
    &-img {
      padding-right: 0.5vw;
      width: 4vw;
      padding-bottom: 0.5vh;
    }
  }
}
