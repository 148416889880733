.Institutional {
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;

    &_card1 {
      background-color: white;
      width: 100%;
      overflow: hidden;
      &--txt {
        font-size: 2.8vw;
        font-weight: 400;
      }
    }

    &_card2 {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden;
      background-color: #00539f;

      &--contentTxt {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2.5vw;
        flex-direction: column;
        width: 100%;
        height: 60vh;

        &__txt {
          font-size: 9.2px;
          font-weight: 500;
          color: white;
        }
      }
      &--contentImg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        &__imgMobile {
          width: 100%;
          height: 20vh;
        }
        &__imgDesktop {
          display: none;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .Institutional {
    &-container {
      &_card1 {
        &--txt {
          font-size: 1.2vw;
        }
      }

      &_card2 {
        height: 90vh;
        flex-direction: row;
        &--contentTxt {
          text-align: left;
          align-items: flex-start;
          width: 60%;
          height: 100%;
          &__txt {
            font-size: 1.2vw;
            font-weight: 500;
            padding-left: 1vw;
            padding-right: 1vw;
          }
        }
        &--contentImg {
          width: 50%;
          &__imgMobile {
            display: none;
          }
          &__imgDesktop {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
