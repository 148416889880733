.Forms {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 4vh;
  background-color: white;
  &-container {
    text-align: center;
    &_title {
      width: 100%;
      letter-spacing: 0.1vw;
      font-size: 5vw;
      font-weight: 600;
      color: black;
    }
    &_subTitle {
      width: 100%;
      padding-top: 2vh;
      font-size: 4vw;
      font-weight: 600;
      color: black;
    }
    &_buttons {
      padding-top: 3vh;
      &--button {
        padding: 2vw;
        cursor: pointer;
        &:hover {
          scale: 1.03;
          transition: 0.7s;
        }
        &__txt {
          color: white;
          background-color: #00539f;
          border-radius: 8px;
          box-shadow: 10px 5px 5px black;
          padding-top: 2vh;
          padding-bottom: 2vh;
          font-weight: 600;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .Forms {
    height: 90vh;
    padding-top: 5vh;
    &-container {
      width: 80%;
      &_title {
        font-size: 3vw;
      }
      &_subTitle {
        padding-top: 5vh;
        font-size: 1.5vw;
      }
      &_buttons {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: row;
        &--button {
          width: 100%;
          &:hover {
          }
          &__txt {
            font-size: 1.2vw;
            padding-top: 3vh;
            padding-bottom: 3vh;
          }
        }
      }
    }
  }
}
