.Home {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-image: url("../../../public/Home/option3.jpg");
  background-size: cover;
  background-repeat: no-repeat;

  &-container {
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;

    &_title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-left: 5vw;
      height: 30vh;
      &--txt {
        font-size: 14vw;
        font-weight: 600;
        color: whitesmoke;
      }
      &--subtxt {
        font-size: 4.5vw;
        font-weight: 600;
        color: whitesmoke;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .Home {
    height: 100vh;
    &-container {
      &_title {
        padding-left: 0;
        padding-right: 48vw;
        height: 50vh;
        &--txt {
          font-size: 5vw;
        }
        &--subtxt {
          font-size: 2.5vw;
        }
      }
    }
  }
}
