.Representations {
  background-color: white;
  &-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 4vh;
    &_title {
      font-size: 5vw;
      padding: 1vh;
      width: 100%;
      text-align: center;
      font-weight: 600;
    }
    &_content {
      width: 100%;
      &--delta {
        padding-top: 10vh;
        width: 40%;
        &__logo {
          padding-top: 5.5vh;
          width: 75%;
        }
        &__alcd {
          width: 100%;
          font-size: 2vw;
          &---img {
            width: 100%;
            padding-left: 15vw;
          }
        }
      }
      &--qatar {
        width: 40%;
        padding-top: 4vh;
        &__logo {
          padding-top: 3.5vh;
          width: 100%;
        }
        &__globe {
          width: 100%;
          font-size: 2vw;
          &---img {
            width: 100%;
            padding-left: 15vw;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .Representations {
    height: 90vh;
    &-container {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      &_title {
        font-size: 3vw;
        font-weight: 600;
        padding-top: 5vh;
      }
      &_content {
        padding-top: 15vh;
        &--delta {
          width: 32%;
          &__logo {
            width: 80%;
          }
          &__alcd {
            width: 100%;
            font-size: 1.7vw;
            &---img {
              top: 0;
              width: 90%;
              padding-left: 20vw;
            }
          }
        }
        &--qatar {
          width: 35%;
          &__logo {
            width: 100%;
          }
          &__globe {
            font-size: 1.5vw;
            &---img {
              padding-top: 1.5vh;
              padding-left: 15vw;
            }
          }
        }
      }
    }
  }
}
