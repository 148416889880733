.Services {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: #00539f;
  &-container {
    width: 100%;
    &_title {
      font-size: 6vw;
      font-weight: 400;
      text-align: center;
      padding-top: 2vh;
      padding-bottom: 2vh;
      color: white;
    }
    &_cards {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: row;
      width: 100%;
      padding-bottom: 2vh;
      &--card {
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: column;
        text-align: center;
        width: 30%;
        box-shadow: 10px 5px 5px black;
        background-color: white;
        &__title {
          font-size: 3.8vw;
          font-weight: 600;
          color: black;
        }
        &__img {
          width: 20vw;
          border: 1px solid white;
        }
        &__info {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding-top: 3vh;
          padding-bottom: 2vh;
          &---button {
            background-color: rgb(151, 150, 150);
            height: 4vh;
            width: 20vw;
            color: black;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-content h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: black;
}

.modal-content_info {
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: row;
}

.modal-content_info--1 {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  padding: 1vw;
}

.modal-content_info--2 {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  width: 70%;
}

.modal-content p {
  font-size: 1.8vw;
  margin-bottom: 5px;
  color: black;
  font-weight: 600;
}

.modal-content_cont {
  display: none;
}

.modal-content_cont--img {
}

.close-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

@media screen and (min-width: 1200px) {
  .Services {
    height: 90vh;
    &-container {
      &_title {
        font-size: 3vw;
        font-weight: 600;
      }
      &_cards {
        flex-direction: row;
        justify-content: space-evenly;
        &--card {
          width: 25%;
          &__title {
            font-size: 2vw;
          }
          &__img {
            width: 15vw;
          }
          &__info {
            &---button {
              font-size: 1.2vw;
              padding-bottom: 3vh;
              width: 6vw;
            }
          }
        }
      }
    }
  }
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
  }

  .modal-content {
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .modal-content h2 {
    display: flex;
    align-items: start;
    font-size: 2.5vw;
    padding-left: 1vw;
    width: 50%;
  }

  .modal-content_info {
  }

  .modal-content_info--1 {
  }

  .modal-content_info--2 {
    width: 50%;
  }

  .modal-content p {
    font-size: 0.8vw;
  }

  .modal-content_cont {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1vw;
  }

  .modal-content_cont--img {
    width: 100%;
    box-shadow: 10px 5px 5px black;
  }

  .close-button {
  }
}
